import React, { memo } from 'react'
import { Box, Flex, Text, BoxProps as ChakraBoxProps } from '@chakra-ui/react'
import { useInView } from 'react-intersection-observer'
import { GatsbyImage } from 'gatsby-plugin-image'

interface IAboutSection extends ChakraBoxProps {
  content: string | React.ReactNode
  sectionTitle?: string | React.ReactNode
  index?: number
  image?: Image
  imagePlacement?: 'left' | 'right'
}

const animationStyles = {
  transform: 'translateY(0)',
  transition: 'opacity 0.6s 0.1s, transform 0.6s ease',
}

const h1UnderlineStyles = {
  content: `""`,
  width: ['72px', '72px', '100%'],
  height: '0.2rem',
  position: 'absolute',
  bottom: '-0.2rem',
  left: '50%',
  transform: 'translate(-50%)',
}

const AboutSection: React.FC<IAboutSection> = memo(
  ({
    sectionTitle,
    content,
    image,
    imagePlacement = 'right',
    color,
    index,
    ...props
  }: IAboutSection) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      delay: 300,
    })

    // Text placement for screen size "lg" and above
    const textPlacement = imagePlacement === 'left' ? 2 : 1

    return (
      <Box
        as="section"
        color={color}
        ref={ref}
        opacity={inView ? 1 : 0}
        {...(inView ? animationStyles : {})}
        {...props}
      >
        <Flex
          flexDirection={['column', 'column', 'row']}
          justifyContent={['center', 'center', 'flex-start']}
          columnGap={10}
          rowGap={4}
        >
          {/* Image goes here if image provided and imagePlacement is "left" */}
          {image && imagePlacement === 'left' && (
            <Box
              height="100%"
              width={['100%', '100%', '50%']}
              order={[2, 2, 1]}
            >
              <GatsbyImage image={image.data} alt={image.alt} />
            </Box>
          )}

          {/* Section content here */}
          <Box
            height="100%"
            width={['100%', '100%', '50%']}
            order={[1, 1, textPlacement]}
          >
            {sectionTitle && (
              <Text
                as="h1"
                position="relative"
                display="inline-block"
                color={color}
                textTransform="uppercase"
                textStyle="h2"
                textAlign={[
                  'center',
                  'center',
                  imagePlacement === 'left' ? 'right' : 'left',
                ]}
                whiteSpace="nowrap"
                width="100%"
                mb={6}
                _after={{
                  bg: color,
                  ...h1UnderlineStyles,
                }}
              >
                {sectionTitle}
              </Text>
            )}

            {typeof content === 'string' ? (
              <Text textStyle="body" fontSize="lg">
                {content}
              </Text>
            ) : (
              content
            )}
          </Box>

          {/* Image goes here if image provided and imagePlacement is "right" */}
          {image && imagePlacement === 'right' && (
            <Box height="100%" width={['100%', '100%', '50%']} order={2}>
              <GatsbyImage image={image.data} alt={image.alt} />
            </Box>
          )}
        </Flex>
      </Box>
    )
  }
)
export default memo(AboutSection)
