import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Flex, Box } from '@chakra-ui/layout'
import { useColorModeValue } from '@chakra-ui/react'
import { getImage } from 'gatsby-plugin-image'
import { capitalCase } from 'change-case'
import { nanoid } from 'nanoid'

import { getFileNameFromPath } from '@/utils/parsing-utils'
import { ABOUT_INFO } from '@/data/business-about'
import AboutSection from './AboutSection'

// Change height and aspectRatio to control image size. Can NOT use layout: FULL_WIDTH if specifying width/height
const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { regex: "/(images)/(about)/" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(aspectRatio: 0.9, transformOptions: { fit: COVER })
        }
      }
    }
  }
`
// aspectRatio: 1
const About: React.FC = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(query)

  const images = nodes.map((node) => {
    // Get the Gatsby Image Data
    const data = getImage(node)

    // Extract the file name to use for the alt
    const path = node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
    const fileName = path ? getFileNameFromPath(path, true) : ''
    const alt = capitalCase(fileName)

    return {
      data,
      alt,
    }
  })

  return (
    <Box id="about" height="inherit" minHeight="inherit" mb={10}>
      {ABOUT_INFO.map((data, index) => {
        const { title, content, imageIndex } = data
        const isEven = index % 2 === 0
        const image =
          imageIndex < images.length ? images[imageIndex] : undefined
        const imagePlacement = isEven ? 'left' : 'right'
        return (
          <Flex
            key={nanoid()}
            justifyContent="center"
            alignItems="center"
            p={8}
          >
            <AboutSection
              sectionTitle={title}
              index={index}
              content={content}
              image={image}
              imagePlacement={imagePlacement}
              color={useColorModeValue(
                'light.container.text',
                'dark.container.text'
              )}
              width={['100%', '100%', '50rem']}
              py={6}
              px={10}
            />
          </Flex>
        )
      })}
    </Box>
  )
}

export default memo(About)
