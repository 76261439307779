import React from 'react'
import Layout from '@/components/Layout'
import About from '@/components/pages/About'

export default () => {
  return (
    <Layout
      title="Nilly's Burgers - About"
      metaDescription="We began our journey as a backyard pop-up, serving homestyle burgers. We're all about serving the classics and creating memorable experiences for our guests."
    >
      <About />
    </Layout>
  )
}
