export const trimExtension = (name: string) => {
  return name.substr(0, name.lastIndexOf('.'))
}

export const getFileNameFromPath = (
  path: string,
  excludeExtension?: boolean
) => {
  const fileName = path.split('/').pop()
  return excludeExtension ? trimExtension(fileName) : fileName
}
