type AboutInfo = {
  content: string
  // Index of images from 'assets/images/about'
  imageIndex?: number
  title?: string
}

export const ABOUT_INFO: AboutInfo[] = [
  {
    title: `About Us`,
    content: `A long-standing idea, initiated during a time of complete uncertainty, now making it’s way into fruition as a brick and mortar — Nilly’s Neighborhood Burger Shop is here to stay.`,
    imageIndex: 0,
  },
  {
    title: `Our Story`,
    content: `We started off in April of 2020 as a backyard pop-up, or what we called, “Burger Runs”. Every other week we would get together a preorder list and that Saturday we would have our guests pick up and enjoy our burgers. From there on out, we knew we had something special, yet so simple. 
So, we went after it.`,
    imageIndex: 2,
  },
  {
    title: `Our Food`,
    content: `We pride ourselves on what gets made around here. Our meat patties are prepared from scratch — we break down beef chuck, grind it (no special or fancy blends), and portion them.  Our Scratch Pickles are sliced to a thick cut and boiled in spices to give it our distinct taste. The Buttermilk Home Ranch we use is curated in-house, routinely. And our onions thinly sliced, everyday.`,
    imageIndex: 3,
  },
  {
    title: 'Stop By',
    content: `Our shop operates on short-order cooking, meaning we want to get your food to you as quickly as possible. We’re all about serving up the classics, making great connections, and creating a memorable experience for our guests. Come visit us! We’re located in Artesia, California. See you soon! `,
    imageIndex: 1,
  },
]
